import { Icon } from "@/components/Icon"
import React from "react"
import classes from "@/pages/search/grassroots/GrassrootsInlineRightSection.module.css"
import { Tooltip, Group, Stack, ActionIcon } from "@mantine/core"

export const GrassrootsInlineRightSection = (props) => {
    return (
        <Stack spacing="xs">
            <iframe src={props.videoUrl} title="video thumbnail"></iframe>
            <Group spacing="xs">
                <Tooltip withArrow label="Approve" position="bottom">
                    <ActionIcon aria-label="Approve" className={classes.checkIcon}>
                        <Icon icon="check"></Icon>
                    </ActionIcon>
                </Tooltip>
                <Tooltip withArrow label="Reject" position="bottom">
                    <ActionIcon aria-label="Reject" className={classes.deleteIcon}>
                        <Icon icon="trash"></Icon>
                    </ActionIcon>
                </Tooltip>
            </Group>
        </Stack>
    )
}
