import React from "react"

import * as S from "QuorumGrassroots/campaign-forms/components/StoryForm/RemoveVideoModal/RemoveVideoModal.styles"
import { useMutation } from "@tanstack/react-query"
import { deleteVideoFromGatherVoices } from "QuorumGrassroots/services"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
import { useSearchParams } from "react-router-dom"

interface RemoveVideoModalProps {
    onClose: () => void
    isOpened: boolean
    t: (text: string) => string

    campaignId: string
    gatherVoiceVideoId: string
    gatherVoiceUserId: string
}

export const RemoveVideoModal = (props: RemoveVideoModalProps) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const removeMutation = useMutation({
        mutationFn: () =>
            deleteVideoFromGatherVoices({
                campaign_id: props.campaignId,
                gather_voices_video_id: props.gatherVoiceVideoId,
                gather_voices_user_id: props.gatherVoiceUserId,
            }),
        onError: (error) => {
            window.swal({ icon: "error", title: "Unable to delete video" })
        },
        onSuccess: () => {
            props.onClose()
            setSearchParams({})
        },
    })

    return (
        <S.StyledModal
            onClose={props.onClose}
            closeOnClickOutside={!removeMutation.isLoading}
            withCloseButton={false}
            opened={props.isOpened}
            centered
            size="auto"
        >
            <S.Container>
                <S.IconContainer>
                    <S.InnerIconContainer>
                        <i className="fa fa-exclamation" aria-hidden="true" />
                    </S.InnerIconContainer>
                </S.IconContainer>

                <S.Body>
                    <S.Title>{props.t("campaign.grassroots_story.remove_video")}</S.Title>
                    <S.Description>{props.t("campaign.grassroots_story.cannot_undo")}</S.Description>
                </S.Body>

                <S.ButtonsContainer>
                    <S.RemoveButton
                        loading={removeMutation.isLoading}
                        disabled={removeMutation.isLoading}
                        loaderProps={{ color: "gray" }}
                        type="button"
                        variant="outline"
                        onClick={() => removeMutation.mutate()}
                    >
                        {props.t("campaign.grassroots_story.remove")}
                    </S.RemoveButton>
                    <S.KeepButton
                        disabled={removeMutation.isLoading}
                        type="button"
                        variant="filled"
                        onClick={props.onClose}
                    >
                        {props.t("campaign.grassroots_story.keep")}
                    </S.KeepButton>
                </S.ButtonsContainer>
            </S.Container>
        </S.StyledModal>
    )
}
