import React, { useRef, useState } from "react"
import { Trans } from "react-i18next"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import {
    StyledAnchor,
    StyledRecordButton,
    StyledRecordingArea,
    StyledTermsOfServiceCheckbox,
    StyledTermsOfServiceContainer,
} from "QuorumGrassroots/campaign-forms/components/StoryForm/StoryForm.styles"
import {
    updateSupporterHasParticipatedInCampaigns,
    postGrassrootsAction,
    useVideoRecordingURL,
    useShareStoryVideo,
} from "QuorumGrassroots/services"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Textarea } from "@mantine/core"
import { useMutation } from "@tanstack/react-query"
import { Campaign } from "@/types/djangio"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
import BACKENDERROR from "frontend/imports/backenderror"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import { getPointsForCampaign } from "QuorumGrassroots/campaign-forms/helpers"
import { withGamificationModal } from "QuorumGrassroots/campaign-forms/wrappers/withGamificationModal"
import { getPathObject, requiredFieldValidation, runUserJavascript } from "QuorumGrassroots/helperFunctions"
import { useGrassrootsActionCenterSettings } from "QuorumGrassroots/services/grassrootsActionCenterSettings"
import { getReduxlessThanksDisplayProps } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay"
import { VideoPreview } from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoPreview/VideoPreview"

const { ShareStoryType } = DjangIO.app.grassroots.campaign.types

const STORY_TEXT_SESSION_KEY = "storyText"

interface Video {
    share_story_video_id: string
    share_story_video_url: string
}

const getTextFromSession = (campaignId: string | undefined) => {
    const sessionTextData = sessionStorage.getItem(STORY_TEXT_SESSION_KEY) ?? "{}"
    const { text, campaignId: sessionCampaignId } = JSON.parse(sessionTextData)
    return !!campaignId && campaignId === sessionCampaignId ? text : ""
}

const checkHasVideoEnabled = (storyType: number) => {
    return ([ShareStoryType.text_and_video.value, ShareStoryType.video.value] as number[]).includes(storyType)
}

export const submitStory = async ({ campaign, text, video }: { campaign: Campaign; text: string; video: Video }) => {
    const hasVideoEnabled = checkHasVideoEnabled(campaign.share_story_type)
    const body = {
        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaign.id),
        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(window.userdata.id),
        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(window.organization.id),
        supporter_action_type: campaign.campaign_type,
        action_center: window.action_center_settings.resource_uri,
        points_earned: getPointsForCampaign(campaign),
        ...(hasVideoEnabled && Boolean(video.share_story_video_id) ? video : {}),
        ...(text ? { text } : {}),
    }
    return postGrassrootsAction(body)
}

export const StoryForm = withGamificationModal(({ t, ...props }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const actionCenterSettings = useGrassrootsActionCenterSettings(props.campaign.action_center_id)
    const recordingQuery = useVideoRecordingURL(props.campaign)

    const [checked, setChecked] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [text, setText] = React.useState(getTextFromSession(props.campaign?.id) ?? "")

    const textFieldDirtyRef = useRef(false)
    const isTextFieldDirty = textFieldDirtyRef.current
    const videoQuery = useShareStoryVideo(
        searchParams.get("vId"),
        searchParams.get("uId"),
        props.campaign?.id.toString(),
    )

    const hasRecordedVideo = searchParams.has("vId") && searchParams.has("uId")
    const hasVideoEnabled = checkHasVideoEnabled(props.campaign.share_story_type)
    const hasOnlyText = props.campaign.share_story_type === ShareStoryType.text.value
    const hasOnlyVideo = props.campaign.share_story_type === ShareStoryType.video.value
    const hasVideoAndText = props.campaign.share_story_type === ShareStoryType.text_and_video.value
    const hasTextEnabled = hasOnlyText || hasVideoAndText

    const submitMutation = useMutation({
        mutationFn: () =>
            submitStory({
                campaign: props.campaign,
                text,
                video: {
                    share_story_video_id: searchParams.get("vId"),
                    share_story_video_url: videoQuery.data?.video_embedded_url,
                },
            }),
        onError: (error) => {
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        },
        onSuccess: () => handleSuccess(),
    })

    const handleSuccess = () => {
        props.showGamificationLevelUpModalIfEnabled()
        runUserJavascript(props.campaign.custom_thank_you_javascript)
        const redirectDetails =
            props.campaign.success_redirection_url && getPathObject(props.campaign.success_redirection_url)
        if (!props.isEmbedded && redirectDetails) {
            if (redirectDetails.isInternal) {
                navigate(redirectDetails.url)
            } else {
                swal({ icon: "success", title: "Thank you! You are now being redirected..." })
                window.location.assign(redirectDetails.url)
            }
        } else {
            setIsFinished(true)
        }
        sessionStorage.removeItem(STORY_TEXT_SESSION_KEY)
        updateSupporterHasParticipatedInCampaigns()
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        textFieldDirtyRef.current = true
        setText(e.target.value)
    }

    const handleRecordClick = () => {
        sessionStorage.setItem(STORY_TEXT_SESSION_KEY, JSON.stringify({ text, campaignId: props.campaign.id }))
        window.location.replace(recordingQuery.data.recording_url)
    }

    if (recordingQuery.isLoading && recordingQuery.isFetching) {
        return <StyledContrastText isCampaignPage>Loading...</StyledContrastText>
    }

    const termsOfService = (
        <StyledAnchor href="https://www.quorum.us/home" target="_blank" rel="noopener noreferrer">
            {t("footer.privacy_policy")}
        </StyledAnchor>
    )

    const privacyPolicy = (
        <StyledAnchor href="https://www.quorum.us" target="_blank" rel="noopener noreferrer">
            {t("footer.privacy_policy")}
        </StyledAnchor>
    )

    if (isFinished) {
        const pointsEarned = getPointsForCampaign(props.campaign)
        const thankableProps = getReduxlessThanksDisplayProps(
            actionCenterSettings,
            props.campaign,
            props.isEmbedded,
            pointsEarned,
        )

        return <ThanksDisplay {...thankableProps} />
    }

    return (
        <div className="story-form">
            {hasVideoEnabled && (
                <StyledRecordingArea hasRecordedVideo={hasRecordedVideo}>
                    {hasRecordedVideo ? (
                        <VideoPreview
                            gatherVoicesVideoId={searchParams.get("vId")}
                            gatherVoicesUserId={searchParams.get("uId")}
                            campaign={props.campaign}
                            t={t}
                        />
                    ) : (
                        <StyledRecordButton type="button" onClick={handleRecordClick}>
                            <i className={"fa fa-video-camera"} />
                            Record
                        </StyledRecordButton>
                    )}
                </StyledRecordingArea>
            )}
            {hasTextEnabled && (
                <Textarea
                    label={t("campaign.share.text_label")}
                    placeholder={t("campaign.share.text_placeholder")}
                    value={text}
                    onChange={handleTextChange}
                    error={hasOnlyText && isTextFieldDirty ? requiredFieldValidation(text) : ""}
                />
            )}
            {hasVideoEnabled && (
                <StyledTermsOfServiceContainer>
                    <StyledTermsOfServiceCheckbox
                        checked={checked}
                        onChange={(event) => setChecked(event.currentTarget.checked)}
                    />
                    <StyledContrastText isCampaignPage>
                        <Trans
                            i18nKey="campaign.share.privacy_policy_terms_service"
                            values={{
                                terms_of_service: t("terms_of_service"),
                                privacy_policy: t("footer.privacy_policy"),
                            }}
                            components={[termsOfService, privacyPolicy]}
                        />
                    </StyledContrastText>
                </StyledTermsOfServiceContainer>
            )}
            <StyledButton
                onClick={submitMutation.mutate}
                disabled={
                    submitMutation.isLoading ||
                    (hasVideoEnabled && (!checked || videoQuery.isFetching || videoQuery.isError)) ||
                    (hasOnlyText && !text) ||
                    (hasVideoAndText && !text && !hasRecordedVideo) ||
                    (hasOnlyVideo && !hasRecordedVideo)
                }
                isCampaignPage
                activateNGGTheme
            >
                {submitMutation.isLoading ? t("form.submitting") : t("form.submit_form")}
            </StyledButton>
        </div>
    )
})
